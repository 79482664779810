<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import skeletonLoading from "@/components/skeleton";
import appConfig from "@/app.config";
import axios from "axios";
import { convertDateTime } from "@/_helpers/index";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, skeletonLoading },
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "me",
      isLoading: false,
      setData: [],
      typeform: {
        old_password: "",
        new_password: "",
        password_confirmation: "",
      },
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      old_password: {
        required,
        minLength: minLength(8),
      },
      new_password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  created() {
    this.getDataProfile();
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    formatDate(value) {
      return convertDateTime(value);
    },
    async getDataProfile() {
      const dataProfile = localStorage.getItem("account_data");
      this.isLoading = true;
      if (dataProfile.length > 0) {
        this.setData = JSON.parse(dataProfile);
        this.isLoading = false;
      } else {
        this.isLoading = true;
        await axios
          .get(this.apiUrl)
          .then((resp) => {
            this.setData = resp;
            this.isLoading = false;
          })
          .catch((error) => {
            if (process.env.NODE_ENV !== "production") {
              console.error(error);
            }
            this.isLoading = false;
          });
      }
    },

    // eslint-disable-next-line no-unused-vars
    async onSubmit() {
      this.isLoading = true;
      this.typesubmit = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await axios
          .patch(this.apiUrl + "/password", this.typeform)
          .then(() => {
            this.isLoading = false;
            this.getDataProfile();

            this.$notify({
              message: "Data Berhasil di Ubah",
              position: "bottom-right",
              type: "success",
              duration: 5000,
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (process.env.NODE_ENV !== "production") {
              console.log(error);
            }
            this.$store.dispatch("notification/error", error);
            this.$notify.error({
              message: this.notification.message,
              position: "bottom-right",
              duration: 5000,
            });
          });
      }
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <div class="row mb-4" v-if="!isLoading">
      <div class="col-xl-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <div class="clearfix"></div>
              <div>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/666/666201.png"
                  alt
                  class="avatar-lg rounded-circle img-thumbnail"
                />
              </div>
              <h5 class="mt-3 mb-1">{{ setData.name }}</h5>
              <p class="text-muted">
                <span
                  class="badge bg-soft-primary text-uppercase font-size-12 fw-bold p-2"
                  >{{ setData.role }}</span
                >
              </p>
            </div>

            <hr class="my-4" />

            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <p class="mb-1">Name :</p>
                  <h5 class="font-size-16">{{ setData.name }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">No. HP :</p>
                  <h5 class="font-size-16">{{ setData.phone_number }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Jenis Kelamin :</p>
                  <h5 class="font-size-16">{{ setData.gender }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">E-mail :</p>
                  <h5 class="font-size-16">{{ setData.email }}</h5>
                </div>
                <div class="mt-4">
                  <p class="mb-1">Diperbarui :</p>
                  <h5 class="font-size-16">
                    {{ formatDate(setData.updated_at) }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <b-card>
          <b-card-header>
            <h5 class="m-0">Ganti Password</h5>
          </b-card-header>
          <b-card-body>
            <form action="#" ref="formData" @submit.prevent="onSubmit">
              <div class="mb-3">
                <label>Password Saat Ini</label>
                <input
                  v-model="typeform.old_password"
                  type="password"
                  class="form-control"
                  placeholder="Masukkan password saat ini"
                  name="old_password"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.old_password.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.old_password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.old_password.required"
                    >Kolom ini harus di isi.</span
                  >
                  <span v-if="!$v.typeform.old_password.minLength"
                    >Password harus berisikan min.6 karakter.</span
                  >
                </div>
              </div>

              <div class="mb-3">
                <label>Password Baru</label>
                <div>
                  <input
                    v-model="typeform.new_password"
                    type="password"
                    name="new_password"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        typesubmit && $v.typeform.new_password.$error,
                    }"
                    placeholder="Masukkan password baru"
                  />
                  <div
                    v-if="typesubmit && $v.typeform.new_password.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.typeform.new_password.required"
                      >Kolom ini harus di isi.</span
                    >
                    <span v-if="!$v.typeform.new_password.minLength"
                      >Password harus berisikan min.6 karakter.</span
                    >
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label>Konfirmasi Password Baru</label>
                <input
                  v-model="typeform.password_confirmation"
                  type="password"
                  name="password_confirmation"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      typesubmit && $v.typeform.password_confirmation.$error,
                  }"
                  placeholder="Masukkan ulang password baru"
                />
                <div
                  v-if="typesubmit && $v.typeform.password_confirmation.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.password_confirmation.required"
                    >Kolom ini harus di isi.</span
                  >
                  <span
                    v-else-if="
                      !$v.typeform.password_confirmation.sameAsPassword
                    "
                    >Password harus sama dengan password baru.</span
                  >
                </div>
              </div>
              <div class="mb-3 mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="row mb-4" v-else>
      <div class="col-xl-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <div class="clearfix"></div>
              <div class="d-flex justify-content-center">
                <skeletonLoading
                  class="avatar-lg rounded-circle img-thumbnail"
                />
              </div>
              <div
                class="d-flex justify-content-center flex-column align-items-center"
              >
                <skeletonLoading
                  class="mt-2 mb-1"
                  height="20px"
                  width="80px"
                  borderRadius="5px"
                />
                <skeletonLoading
                  height="20px"
                  width="80px"
                  borderRadius="5px"
                />
              </div>
            </div>

            <hr class="my-4" />

            <div class="text-muted">
              <div class="table-responsive mt-4 mb-0">
                <div>
                  <skeletonLoading
                    height="20px"
                    width="80px"
                    borderRadius="5px"
                    class="mb-2"
                  />
                  <skeletonLoading
                    height="25px"
                    borderRadius="5px"
                    width="100%"
                  />
                </div>
                <div class="mt-4">
                  <skeletonLoading
                    height="20px"
                    width="80px"
                    borderRadius="5px"
                    class="mb-2"
                  />
                  <skeletonLoading
                    height="25px"
                    borderRadius="5px"
                    width="100%"
                  />
                </div>
                <div class="mt-4">
                  <skeletonLoading
                    height="20px"
                    width="80px"
                    borderRadius="5px"
                    class="mb-2"
                  />
                  <skeletonLoading
                    height="25px"
                    borderRadius="5px"
                    width="100%"
                  />
                </div>
                <div class="mt-4">
                  <skeletonLoading
                    height="20px"
                    width="80px"
                    borderRadius="5px"
                    class="mb-2"
                  />
                  <skeletonLoading
                    height="25px"
                    borderRadius="5px"
                    width="100%"
                  />
                </div>
                <div class="mt-4">
                  <skeletonLoading
                    height="20px"
                    width="80px"
                    borderRadius="5px"
                    class="mb-2"
                  />
                  <skeletonLoading
                    height="25px"
                    borderRadius="5px"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8">
        <b-card>
          <skeletonLoading
            height="40px"
            width="100%"
            borderRadius="5px"
            class="p-3"
          />
          <b-card-body>
            <form action="#" ref="formData" @submit.prevent="onSubmit">
              <div class="mb-3">
                <skeletonLoading
                  height="20px"
                  width="80px"
                  borderRadius="5px"
                  class="mb-2"
                />
                <skeletonLoading
                  height="30px"
                  width="100%"
                  borderRadius="5px"
                  class=""
                />
              </div>
              <div class="mb-3">
                <skeletonLoading
                  height="20px"
                  width="80px"
                  borderRadius="5px"
                  class="mb-2"
                />
                <skeletonLoading
                  height="30px"
                  width="100%"
                  borderRadius="5px"
                  class=""
                />
              </div>
              <div class="mb-3">
                <skeletonLoading
                  height="20px"
                  width="80px"
                  borderRadius="5px"
                  class="mb-2"
                />
                <skeletonLoading
                  height="30px"
                  width="100%"
                  borderRadius="5px"
                  class=""
                />
              </div>
              <div class="mb-3 mb-0">
                <div>
                  <skeletonLoading
                    height="30px"
                    width="100px"
                    borderRadius="5px"
                    class=""
                  />
                </div>
              </div>
            </form>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
